import React from 'react';
import { Container, Typography, Grid, Paper, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags, faStore, faUserCog, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import bannerimage from '../assets/images/cartwo_cleanup.jpg';

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.grey[800],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    transition: 'box-shadow 0.3s, transform 0.5s ease-in-out, color 0.3s',
    '&:hover': {
        boxShadow: theme.shadows[10],
        backgroundColor: '#111',
        transform: 'translateY(-10px)',
        '& .hover-text': {
            color: '#ffffff', 
        },
    },
}));


const Specification = () => {
    return (
        <>
            {/* Top Banner */}

            <Container maxWidth="lg" sx={{ py: '60px' }}>
                <div style={{ textAlign: 'center', marginBottom: '48px' }}>
                    <Typography
                        variant="h6"
                        color="primary"
                        sx={{
                            fontWeight: 'bold',
                            color: '#d32f2f',
                            letterSpacing: 0.3,
                            fontSize: '35px',
                            fontFamily: '"Poppins", "Arial", sans-serif',
                            textTransform: 'uppercase',
                            textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        Our Specification
                    </Typography>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 'bold',
                            color: '#242424',
                            fontFamily: '"Montserrat", "Arial", sans-serif',
                            fontSize: '18px',
                            textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        Why Choose Us?
                    </Typography>
                </div>

                <Grid container spacing={4}>
                    {/* Affordable Prices */}
                    <Grid item xs={12} md={6}>
                        <StyledPaper
                            elevation={3}
                            sx={{
                                backgroundColor: 'rgba(237, 242, 250, 0.62)',
                                height: 'auto',
                                minHeight: '200px',
                                transition: '0.3s ease-in-out',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                <FontAwesomeIcon
                                    icon={faTags}
                                    size="3x"
                                    style={{ color: '#d32f2f', marginRight: '16px' }}
                                />
                                <Typography
                                    className="hover-text"
                                    variant="h2"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#242424',
                                        letterSpacing: 0.3,
                                        fontSize: '24px',
                                        fontFamily: '"Poppins", "Arial", sans-serif',
                                        textTransform: 'uppercase',
                                        textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    Affordable Prices
                                </Typography>
                            </div>
                            <Typography
                                color="textSecondary"
                                className="hover-text"
                                sx={{
                                    color: '#242424',
                                    fontFamily: '"Montserrat", "Arial", sans-serif',
                                    fontSize: '15px',
                                    transition: '0.3s ease-in-out',
                                }}
                            >
                               We believe in making quality auto parts accessible. With competitive pricing across our entire range, you can get the parts you need without stretching your budget.
                            </Typography>
                        </StyledPaper>
                    </Grid>

                    {/* Convenience */}
                    <Grid item xs={12} md={6}>
                        <StyledPaper
                            elevation={3}
                            sx={{
                                backgroundColor: 'rgba(237, 242, 250, 0.62)',
                                height: 'auto',
                                minHeight: '200px',
                                transition: '0.3s ease-in-out',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                <FontAwesomeIcon
                                    icon={faStore}
                                    size="3x"
                                    style={{ color: '#d32f2f', marginRight: '16px' }}
                                />
                                <Typography
                                    className="hover-text"
                                    variant="h2"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#242424',
                                        letterSpacing: 0.3,
                                        fontSize: '24px',
                                        fontFamily: '"Poppins", "Arial", sans-serif',
                                        textTransform: 'uppercase',
                                        textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    Convenience
                                </Typography>
                            </div>
                            <Typography
                                color="textSecondary"
                                className="hover-text"
                                sx={{
                                    color: '#242424',
                                    fontFamily: '"Montserrat", "Arial", sans-serif',
                                    fontSize: '15px',
                                    transition: '0.3s ease-in-out',
                                }}
                            >
                                Our streamlined online platform and fast, reliable shipping mean that you can order the parts you need from the comfort of your home or garage, and we’ll have them delivered straight to your door.
                            </Typography>
                        </StyledPaper>
                    </Grid>

                    {/* Expert Advice */}
                    <Grid item xs={12} md={6}>
                        <StyledPaper
                            elevation={3}
                            sx={{
                                backgroundColor: 'rgba(237, 242, 250, 0.62)',
                                height: 'auto',
                                minHeight: '200px',
                                transition: '0.3s ease-in-out',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                <FontAwesomeIcon
                                    icon={faUserCog}
                                    size="3x"
                                    style={{ color: '#d32f2f', marginRight: '16px' }}
                                />
                                <Typography
                                    className="hover-text"
                                    variant="h2"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#242424',
                                        letterSpacing: 0.3,
                                        fontSize: '24px',
                                        fontFamily: '"Poppins", "Arial", sans-serif',
                                        textTransform: 'uppercase',
                                        textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    Expert Advice
                                </Typography>
                            </div>
                            <Typography
                                color="textSecondary"
                                className="hover-text"
                                sx={{
                                    color: '#242424',
                                    fontFamily: '"Montserrat", "Arial", sans-serif',
                                    fontSize: '15px',
                                    transition: '0.3s ease-in-out',
                                }}
                            >
                                Whether you’re a seasoned mechanic or a DIY enthusiast, our team of automotive experts is ready to guide you. Count on us for personalized support in finding the right parts for your vehicle.
                            </Typography>
                        </StyledPaper>
                    </Grid>

                    {/* Quality Assurance */}
                    <Grid item xs={12} md={6}>
                        <StyledPaper
                            elevation={3}
                            sx={{
                                backgroundColor: 'rgba(237, 242, 250, 0.62)',
                                height: 'auto',
                                minHeight: '200px',
                                transition: '0.3s ease-in-out',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                                <FontAwesomeIcon
                                    icon={faShieldAlt}
                                    size="3x"
                                    style={{ color: '#d32f2f', marginRight: '16px' }}
                                />
                                <Typography
                                    className="hover-text"
                                    variant="h2"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#242424',
                                        letterSpacing: 0.3,
                                        fontSize: '24px',
                                        fontFamily: '"Poppins", "Arial", sans-serif',
                                        textTransform: 'uppercase',
                                        textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    Quality Assurance
                                </Typography>
                            </div>
                            <Typography
                                color="textSecondary"
                                className="hover-text"
                                sx={{
                                    color: '#242424',
                                    fontFamily: '"Montserrat", "Arial", sans-serif',
                                    fontSize: '15px',
                                    transition: '0.3s ease-in-out',
                                }}
                            >
                               At yndautoparts.com, we prioritize quality and customer satisfaction. If you’re not completely happy with your purchase, we’ll make it right. Our commitment to innovation and expanding product options ensures that you’ll find everything from cutting-edge technology to essential aftermarket upgrades.
                            </Typography>
                        </StyledPaper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Specification;
