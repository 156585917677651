import React from 'react';
import { Link } from '@mui/material';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <AppBar position="static" sx={{ backgroundColor: '#000', color: '#fff', borderBottom: '1px solid #ffffff50',
        py: {
      xs: '20px', 
      sm: '20px', 
      md: '0px', 
    },
    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid
            container
            alignItems="center"
            spacing={isMobile ? 2 : 0}
            justifyContent="space-between"
          >
            
            <Grid
              item
              xs={12}
              md={4}
              sm={6}
            >
              <Typography
                variant="h6"
                component="a"
                href="/"
                sx={{
                  textDecoration: 'none',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: isMobile ? '14px' : isTablet ? '16px' : '18px',
                  '&:hover': { color: '#b0b0b0' },
                  display: 'flex',
                  justifyContent: isMobile
                    ? 'center'
                    : isTablet
                    ? 'space-around'
                    : 'space-evenly',
                  alignItems: 'center',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: isTablet ? 1 : 2,
                }}
              >
                yndautoparts.com
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: 'flex',
                justifyContent: isMobile
                  ? 'center'
                  : isTablet
                  ? 'space-around'
                  : 'center',
                alignItems: 'center',
                flexDirection: isMobile ? 'column' : 'row',
                gap: isTablet ? 1 : 2,
              }}
            >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  {/* Phone Section */}
  <Link href="tel:+17255291107" sx={{ color: 'inherit', textDecoration: 'none' }}>
    <PhoneIcon />
  </Link>
  <Typography variant="body1" sx={{ ml: 1, color: '#fff', fontSize: '14px' }}>
    <Link href="tel:+17255291107" sx={{ color: 'inherit', textDecoration: 'none' }}>
      +1-725-529-1107
    </Link>
  </Typography>
</Box>

<Box sx={{ display: 'flex', alignItems: 'center' }}>
  {/* Email Section */}
  <Link href="mailto:contact@yndautoparts.com" sx={{ color: 'inherit', textDecoration: 'none' }}>
    <MailIcon />
  </Link>
  <Typography variant="body1" sx={{ ml: 1, color: '#fff', fontSize: '14px' }}>
    <Link href="mailto:contact@yndautoparts.com" sx={{ color: 'inherit', textDecoration: 'none' }}>
      contact@yndautoparts.com
    </Link>
  </Typography>
</Box>
</Grid>

           
            <Grid
              item
              xs={12}
              md={4} 
              sm={6}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: isMobile
                    ? 'center'
                    : isTablet
                    ? 'normal'
                    : 'flex-end',
                  gap: 1,
                }}
              >
                <IconButton color="inherit" target="_blank" href="https://www.facebook.com">
                  <FacebookIcon />
                </IconButton>
                <IconButton color="inherit" target="_blank" href="https://www.twitter.com">
                  <TwitterIcon />
                </IconButton>
                <IconButton color="inherit" target="_blank" href="https://www.youtube.com">
                  <YouTubeIcon />
                </IconButton>
                <IconButton color="inherit" target="_blank" href="https://www.whatsapp.com">
                  <WhatsAppIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
