import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <div  style={{padding: '60px 0' }}>  
    <Container maxWidth="lg">
      <Box mt={0} p={4} bgcolor="#f9f9f9" borderRadius={2}>
        <Typography variant="h2" gutterBottom align="center" sx={{
            fontFamily: '"Poppins", "Arial", sans-serif',
            fontWeight: 'bold',
            letterSpacing: 0.3,
            textTransform: 'uppercase',
            position: 'relative',
            fontSize: '24px',
            marginBottom: '20px',
            textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
          }}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          At YND Auto Parts, we prioritize the privacy and security of our customers. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website, purchase products, or interact with our services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          <strong>1. Information We Collect</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          We may collect personal information such as your name, email address, shipping address, and payment details during checkout. Additionally, we gather non-personal data like browser type, IP address, and pages visited.
        </Typography>

        <Typography variant="h6" gutterBottom>
        <strong>2. How We Use Your Information</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Your information is used to:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Process orders and deliver products.</Typography>
          </li>
          <li>
            <Typography variant="body1">Communicate with you about your orders and our services.</Typography>
          </li>
          <li>
            <Typography variant="body1">Improve our website, products, and services.</Typography>
          </li>
        </ul>
        <Typography variant="body1" paragraph>
          We do not sell or share your personal information with third parties, except for trusted partners necessary for payment processing and shipping.
        </Typography>

        <Typography variant="h6" gutterBottom>
        <strong>3. Cookies</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Our website may use cookies to enhance your browsing experience. You may disable cookies in your browser settings, though it may impact some features on the site.
        </Typography>

        <Typography variant="h6" gutterBottom>
        <strong>4. Data Security</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          We implement various security measures to safeguard your data, including encryption technologies for transactions and secure data storage. However, no online transaction is 100% secure, so please take care when sharing sensitive information.
        </Typography>

        <Typography variant="h6" gutterBottom>
        <strong>5. Third-Party Links</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these websites.
        </Typography>

        <Typography variant="h6" gutterBottom>
        <strong>6. Your Rights</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          You can request to review, update, or delete your personal data by contacting us at{' '}
          <a href="mailto:contact@yndautoparts.com" style={{
                        color: '#d32f2fb3',
                        textDecoration: 'none',
                        fontSize: '16px',
                        transition: 'color 0.3s ease' 
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.color = '#d32f2f'} 
                      onMouseLeave={(e) => e.currentTarget.style.color = '#d32f2fb3'} 
                    >contact@yndautoparts.com</a>. We respect your rights to data protection under applicable privacy laws.
        </Typography>

        <Typography variant="h6" gutterBottom>
        <strong>7. Changes to This Policy</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy periodically. Any changes will be posted on this page with an updated revision date.
        </Typography>

        <Typography variant="body1" paragraph>
          For any concerns regarding our Privacy Policy, please reach out to us at{' '}
          <a href="mailto:contact@yndautoparts.com" style={{
                        color: '#d32f2fb3',
                        textDecoration: 'none',
                        fontSize: '16px',
                        transition: 'color 0.3s ease' 
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.color = '#d32f2f'} 
                      onMouseLeave={(e) => e.currentTarget.style.color = '#d32f2fb3'} 
                    >contact@yndautoparts.com</a>.
        </Typography>
      </Box>
    </Container>
    </div>
  );
};

export default PrivacyPolicy;
