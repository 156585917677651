import React from 'react';
import { Box, Typography, Container, Grid, Avatar, Button, keyframes } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import '@fontsource/poppins';
import '@fontsource/montserrat'; 
import aboutus from "../assets/images/aboutus.png";
import Specification from './Specification';
import Home from './Home';
import aboutusbg from '../assets/images/carthree.jpg'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email'

// Image links
const teamImage = "https://storage.googleapis.com/a1aa/image/jGx0wXkESXZDAxOOg97ogW3B162Wvo8H4y5l951TENvuXK5E.jpg"; 
const accessoriesImage = "https://storage.googleapis.com/a1aa/image/nNnEuLaJx8L6H1S84O257sUGfmQG3EM9MUx5Der0bdv8eSJnA.jpg"; 
const backgroundImage = "https://path-to-your-background-image.jpg"; // Add your background image URL here

// Keyframes for the animation
const borderAnimation = keyframes`
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  50% {
    border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
`;

// Styled Box component
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0),
  marginTop: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

// Styled Avatar component with animation
const AnimatedAvatar = styled(Avatar)`
  animation: ${borderAnimation} 5s linear infinite;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, .12);
  width: 100%;
  height: auto;
  max-width: 500px;
  max-height: 300px;
  z-index: 0;
`;

// Styled Banner component with background image
const Banner = styled(Box)(({ theme }) => ({
  color: '#fff',
  padding: theme.spacing(4, 0),
  marginBottom: theme.spacing(4),
  borderRadius: '8px',
  textAlign: 'center',
  backgroundImage: `url(${aboutusbg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  padding: '100px 0px',

  // Adding the overlay using ::before
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
    borderRadius: '8px',
  },
}));


// Main component
const About = ({ showSpecification = true,isStandalone = true,showButton = false }) => {
  return (
    <>
      {/* Banner Section */}
      {isStandalone &&(
      <Banner>
        <Container>
          <Typography variant="h3" sx={{
            fontFamily: '"Poppins", "Arial", sans-serif',
            fontWeight: 'bold',
            letterSpacing: 0.3,
            textTransform: 'uppercase',
            position: 'relative',
            fontSize: '35px',
            textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
          }}>
           About Us
          </Typography>
          <Typography variant="h6" sx={{
            fontFamily: '"Montserrat", "Arial", sans-serif',
            fontWeight: 'normal',
            position: 'relative',
            marginTop: 2,
            textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
          }}>
            Your trusted source for durable, reliable auto parts.
          </Typography>
        </Container>
      </Banner>
      )}

      <div className='about-us' style={{ backgroundColor: '#fff', padding: '60px 0' }}>
        <Container>
          <StyledBox sx={{ boxShadow: '0px 0px 0px 0px', backgroundColor: 'transparent', marginTop: '0px', padding: '0' }}>

            <Typography variant="h2" align="center" gutterBottom 
              sx={{
                fontWeight: 'bold',
                color: '#d32f2f', 
                letterSpacing: 0.3, 
                fontSize: '35px',
                fontFamily: '"Poppins", "Arial", sans-serif', 
                textTransform: 'uppercase', 
                textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', 
              }}>
              About Us
            </Typography>

            <Typography variant="h5" align="center" gutterBottom 
              sx={{
                fontWeight: 'bold',
                color: '#242424', 
                fontFamily: '"Montserrat", "Arial", sans-serif', 
                fontSize: '18px',
                textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', 
                marginBottom: '100px',
              }}>
              High-performance auto parts for reliability and excellence on every drive.
            </Typography>

            {/* First Row */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center', paddingLeft: 0, paddingRight: 0, position: 'relative' }}>
                {/* Red shape overlapping the image */}
                <div style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor: '#10429012',
                  position: 'absolute',
                  top: '-10%',
                  left: '0',
                  alignSelf: 'center',
                  borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
                  zIndex: 0,
                  transform: 'rotate(3deg)',
                }} />

                {/* Animated Avatar */}
                <AnimatedAvatar alt="Car Accessories" src={aboutus} />
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Typography variant="body1" paragraph sx={{
                  color: '#242424', 
                  fontFamily: '"Montserrat", "Arial", sans-serif',
                  fontSize: '16px',
                }}>
                  <strong>Welcome to YND Auto Parts,  (16409849 CANADA INC)</strong>  your trusted source for high-quality used engines, auto parts, and vehicle accessories. Based in Canada, we specialize in providing a wide range of reliable parts for all types of vehicles, ensuring our customers can find exactly what they need to keep their cars running smoothly.
                </Typography>
                <Typography variant="body1" paragraph sx={{
                  color: '#242424', 
                  fontFamily: '"Montserrat", "Arial", sans-serif',
                  fontSize: '16px',
                }}>
                 With our headquarters located in Norval, Ontario, we are proud to offer fast and efficient shipping across Canada and the United States. Whether you’re looking for used engines or specific auto parts, our team is dedicated to delivering top-notch products with unbeatable customer service.
                </Typography>
              </Grid>
            </Grid>

            {/* Second Row */}
            <Grid container spacing={3} mt={{ sm: 8, xs: 0, md: 4 }} alignItems="center">
              <Grid item xs={12} sm={6} md={6} sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Typography variant="body1" paragraph sx={{
                  color: '#242424', 
                  fontFamily: '"Montserrat", "Arial", sans-serif',
                  fontSize: '16px',
                }}>
                  At <strong>YND Auto Parts,</strong> we understand the importance of reliable, affordable solutions for maintaining and enhancing your vehicle. That’s why we thoroughly inspect each part for quality and performance, so you can have peace of mind with every purchase.
                </Typography>
                <Typography 
                  variant="body1" 
                  paragraph 
                  mb={{ xs: 0, md: 0 }}
                  sx={{
                    color: '#242424', 
                    fontFamily: '"Montserrat", "Arial", sans-serif',
                    fontSize: '16px',
                  }}
                >
                  For any inquiries or assistance, feel free to contact us at contact@yndautoparts.com or call us at +1-725-529-1107. We’re here to help you find the right parts for your needs.
                </Typography>


                  <Typography variant="body1" paragraph mb={{ xs: 2, md: 0 }} sx={{
                    color: '#000', 
                    fontFamily: '"Montserrat", "Arial", sans-serif',
                    fontSize: '16px',
                    marginTop: '10px',
                  }}>
                  For any inquiries or assistance, feel free to{' '}
                  </Typography>
                  
                  <div>
                    <label style={{
                        fontWeight: 'bold',
                        fontSize: '24px',
                        display: 'block',
                        marginBottom: '5px',
                        marginTop: '15px',
                        color: '#242424'
                      }}>
                      Contact Us
                    </label>
                    <a href="mailto:contact@yndautoparts.com" style={{
                        color: '#242424',
                        display: 'flex',
                        alignItems: 'center',                  
                        textDecoration: 'none',
                        fontSize: '16px',
                        padding: '15px 0px 20px 0px',
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.color = '#d32f2f'} 
                      onMouseLeave={(e) => e.currentTarget.style.color = '#242424'} 
                    ><EmailIcon
                        sx={{
                          fontSize: '16px', 
                          width: '16px',
                          background: '#d32f2f',
                          padding: '8px',
                          borderRadius: '50%',
                          color: '#fff',
                          marginRight: '10px',
                        }}
                      />
                      contact@yndautoparts.com
                    </a>
                  </div>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px'
                    }}>
                   <a href="tel:+17255291107" 
                      style={{
                        color: '#242424',
                        textDecoration: 'none',
                        fontSize: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        transition: 'color 0.3s ease' 
                      }}
                      onMouseEnter={(e) => e.currentTarget.style.color = '#d32f2f'} 
                      onMouseLeave={(e) => e.currentTarget.style.color = '#242424'} 
                    >
                      <PhoneIcon sx={{
                          fontSize: '16px', 
                          width: '16px',
                          background: '#d32f2f',
                          padding: '8px',
                          borderRadius: '50%',
                          color: '#fff',
                          marginRight: '10px',
                        }}></PhoneIcon>
                      +1-725-529-1107
                    </a>

                    <span style={{
                        marginLeft: '8px',
                        fontSize: '16px',
                        color: '#242424'
                      }}>
                    </span>
                  </div>
                  <Typography variant="body1" paragraph style={{
                        fontSize: '16px',
                        color: '#242424',
                        marginBottom: '5px',
                      }}>We’re here to help you find the right parts for your needs.</Typography>
                  <Typography/>

                  <Typography variant="body1" paragraph mb={{ xs: 8, md: 0 }} style={{
                      }}>
                  For more information, please review our{' '}
                  <Link 
                    to="/terms-and-conditions" 
                    style={{ 
                      color: '#d32f2f', 
                      textDecoration: 'none', 
                      transition: 'color 0.3s ease' 
                    }} 
                    onMouseEnter={(e) => e.currentTarget.style.color = '#b71c1c'} 
                    onMouseLeave={(e) => e.currentTarget.style.color = '#d32f2f'} 
                  >
                    Terms and Conditions
                  </Link>
                  {' '}
                  and{' '}
                  <Link to="/privacy-policy" style={{ 
                      color: '#d32f2f', 
                      textDecoration: 'none', 
                      transition: 'color 0.3s ease' 
                    }} 
                    onMouseEnter={(e) => e.currentTarget.style.color = '#b71c1c'} 
                    onMouseLeave={(e) => e.currentTarget.style.color = '#d32f2f'} 
                  >
                    Privacy Policy
                  </Link>.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', justifyContent: 'center', paddingLeft: 0, paddingRight: 0, position: 'relative' }}>
                {/* Red shape overlapping the image */}
                <div style={{
                  width: '80%',
                  height: '100%',
                  backgroundColor: '#10429012',
                  position: 'absolute',
                  top: '-10%',
                  right: '0',
                  alignSelf: 'center',
                  borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
                  zIndex: 0,
                  transform: 'rotate(3deg)',
                }} />

                <AnimatedAvatar alt="Car Accessories" src={accessoriesImage} />
              </Grid>
            </Grid>

            {/* Button Section */}
            {showButton && (
              <Grid container justifyContent={{ xs: 'center', md: 'flex-start' }} sx={{ mt: 4 }}>
                <Grid item>
                <Button
                    variant="contained"
                    size="large"
                    component={Link}
                    to="/about"
                    sx={{
                      fontSize: '16px',
                      fontFamily: '"Montserrat", "Arial", sans-serif',
                      padding: '10px 20px',
                      borderRadius: '8px',
                      background: '#d32f2f',
                      fontWeight: '500',
                      color: '#fff',
                      textTransform: 'none',
                    }}
                  >
                    Know More
                  </Button>

                </Grid>
              </Grid>
)}
          </StyledBox>
        </Container>
      </div>
      
      {showSpecification && <Specification />}
    </>
  );
};

export default About;
