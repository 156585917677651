import React from 'react';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import ProductsPage from './pages/Product';
import ContactPage from './pages/Contact';
import Routing from './Routing/Routing';

const App = () => {
  return (
    <div>
      <Routing/>
    </div>
  );
}

export default App;
