// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Box,
//   Menu,
//   MenuItem,
//   Button,
//   Container,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import logo from "../assets/images/logo_one.png";
// import emailjs from 'emailjs-com'; 
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const pages = [
//   { name: 'Home', path: '/' },
//   { name: 'About Us', path: '/about' },
//   { name: 'Products', path: '/products' },
//   { name: 'Contact Us', path: '/contact' },
//   { name: "Online Store", path: "/OnlineStore" },
// ];

// const Navbar = () => {
//   const [anchorElNav, setAnchorElNav] = useState(null);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//   });

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const sendEmail = (e) => {
//     e.preventDefault();
  
//     // Validate the form fields
//     if (!formData.name.trim()) {
//       toast.error('Name is required');
//       return;
//     }
  
//     if (!formData.email.trim()) {
//       toast.error('Email is required');
//       return;
//     }
  
//     if (!formData.phone.trim()) {
//       toast.error('Phone number is required');
//       return;
//     }
  
//     emailjs.send('service_xs37ipj', 'template_ph7df3a', {
//       name: formData.name,
//       email: formData.email,
//       phone: formData.phone,
//     }, 'TUhvHgQ0Bp4osZuvg')
//       .then((response) => {
//         console.log('Email sent successfully!', response.status, response.text);
//         handleCloseDialog();
//         toast.success('Email sent successfully!');
//       })
//       .catch((error) => {
//         console.error('Error sending email:', error);
//         toast.error('Error sending email. Please try again.');
//       });
//   };
  

//   return (
//     <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
//             <img
//               src={logo}
//               alt="Logo"
//               style={{ height: '80px', borderRadius: '5px' }}
//             />
//             <Typography
//               variant="h6"
//               noWrap
//               component="div"
//               sx={{
//                 color: '#000',
//                 fontWeight: 'bold',
//                 textDecoration: 'none',
//                 '&:hover': { color: '#b0b0b0' },
//               }}
//             >
//             </Typography>
//           </Box>

//           <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
//             <IconButton
//               size="large"
//               aria-label="open menu"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="inherit"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//             >
//               {pages.map((page) => (
//                 <MenuItem key={page.name} onClick={handleCloseNavMenu}>
//                   <Typography textAlign="center" sx={{ color: '#000' }}>
//                     <Link to={page.path} style={{ textDecoration: 'none', color: '#000' }}>
//                       {page.name}
//                     </Link>
//                   </Typography>
//                 </MenuItem>
//               ))}
//               <MenuItem onClick={handleCloseNavMenu}>
//                 <Button
//                   onClick={() => { 
//                     console.log(formData);
//                     handleCloseDialog();
//                   }}
//                   sx={{
//                     backgroundColor: '#d32f2f',
//                     color: '#fff',
//                     fontSize: '16px',
//                     fontFamily: '"Montserrat", "Arial", sans-serif',
//                     padding: '10px 20px',
//                     borderRadius: '8px',
//                     textTransform: 'none',
//                   }}
//                 >
//                   Submit
//                 </Button>
//               </MenuItem>
//             </Menu>
//           </Box>

//           <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
//             {pages.map((page) => (
//               <Button
//                 key={page.name}
//                 component={Link}
//                 to={page.path}
//                 onClick={handleCloseNavMenu}
//                 sx={{
//                   my: 2,
//                   color: '#000',
//                   display: 'block',
//                   paddingInline: '20px',
//                   '&:hover': { backgroundColor: '#d32f2fbd', color: '#fff' },
//                 }}
//               >
//                 {page.name}
//               </Button>
//             ))}
//           </Box>

//           <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
//             <Button
//               variant="contained"
//               size="large"
//               onClick={handleOpenDialog} 
//               sx={{
//                 fontSize: '16px',
//                 fontFamily: '"Montserrat", "Arial", sans-serif',
//                 padding: '10px 20px',
//                 borderRadius: '8px',
//                 background: '#d32f2f',
//                 fontWeight: '500',
//                 color: '#fff',
//                 textTransform: 'none',
//                 alignSelf: 'center',
//               }}
//             >
//               Enquire Now
//             </Button>
//           </Box>
//         </Toolbar>
//       </Container>

//       {/* Dialog for Email Form */}
//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//         <DialogTitle>Contact Us</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             name="name"
//             label="Name"
//             type="text"
//             fullWidth
//             variant="outlined"
//             onChange={handleChange}
//             InputLabelProps={{
//               sx: {
//                 '&.Mui-focused': {
//                   color: '#d32f2f',
//                 },
//               },
//             }}
//             sx={{
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   borderColor: 'gray',
//                 },
//                 '&:hover fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '& input::placeholder': {
//                   color: 'red',
//                 },
//               },
//             }}
//           />
//           <TextField
//             margin="dense"
//             name="email"
//             label="Email"
//             type="email"
//             fullWidth
//             variant="outlined"
//             onChange={handleChange}
//             InputLabelProps={{
//               sx: {
//                 '&.Mui-focused': {
//                   color: '#d32f2f',
//                 },
//               },
//             }}
//             sx={{
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   borderColor: 'gray',
//                 },
//                 '&:hover fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '& input::placeholder': {
//                   color: 'red',
//                 },
//               },
//             }}
//           />
//           <TextField
//             margin="dense"
//             name="phone"
//             label="Phone"
//             type="tel"
//             fullWidth
//             variant="outlined"
//             onChange={handleChange}
//             InputLabelProps={{
//               sx: {
//                 '&.Mui-focused': {
//                   color: '#d32f2f',
//                 },
//               },
//             }}
//             sx={{
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   borderColor: 'gray',
//                 },
//                 '&:hover fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '& input::placeholder': {
//                   color: 'red',
//                 },
//               },
//             }}
//           />
//         </DialogContent>

//         <DialogActions sx={{
//             padding: '0px 20px 20px 0px',
//           }}>
//           <Button onClick={handleCloseDialog} color="primary" sx={{
//                   backgroundColor: '#333',
//                   color: '#fff',
//                   fontSize: '16px',
//                   fontFamily: '"Montserrat", "Arial", sans-serif',
//                   padding: '10px 20px',
//                   borderRadius: '8px',
//                   textTransform: 'none',
//                   '&:hover': {
//                     backgroundColor: '#444',
//                   },
//                 }}
//               >
//             Cancel
//           </Button>
//           <Button onClick={sendEmail} sx={{
//                   backgroundColor: '#d32f2f',
//                   color: '#fff',
//                   fontSize: '16px',
//                   fontFamily: '"Montserrat", "Arial", sans-serif',
//                   padding: '10px 20px',
//                   borderRadius: '8px',
//                   textTransform: 'none',
//                   '&:hover': {
//                     backgroundColor: '#d32f2f94',
//                   },
//                 }}>Submit</Button>

//         </DialogActions>
//       </Dialog>

//       <ToastContainer
//         position="bottom-right" 
//         autoClose={6000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//       />
//     </AppBar>
//   );
// };

// export default Navbar;



// import React, { useState } from 'react';
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Box,
//   Menu,
//   MenuItem,
//   Button,
//   Container,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
// } from '@mui/material';
// import { Link } from 'react-router-dom';
// import MenuIcon from '@mui/icons-material/Menu';
// import logo from "../assets/images/logo_one.png";

// const pages = [
//   { name: 'Home', path: '/' },
//   { name: 'About Us', path: '/about' },
//   { name: 'Products', path: '/products' },
//   { name: 'Contact Us', path: '/contact' },
//   {name: "Payments", path:"/Payments"},
// ];

// const Navbar = () => {
//   const [anchorElNav, setAnchorElNav] = useState(null);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//   });

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   return (
//     <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
//             <img
//               src={logo}
//               alt="Logo"
//               style={{ height: '80px', borderRadius: '5px' }}
//             />
//             <Typography
//               variant="h6"
//               noWrap
//               component="div"
//               sx={{
//                 color: '#000',
//                 fontWeight: 'bold',
//                 textDecoration: 'none',
//                 '&:hover': { color: '#b0b0b0' },
//               }}
//             >
//             </Typography>
//           </Box>

//           <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
//             <IconButton
//               size="large"
//               aria-label="open menu"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="inherit"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//             >
//               {pages.map((page) => (
//                 <MenuItem key={page.name} onClick={handleCloseNavMenu}>
//                   <Typography textAlign="center" sx={{ color: '#000' }}>
//                     <Link to={page.path} style={{ textDecoration: 'none', color: '#000' }}>
//                       {page.name}
//                     </Link>
//                   </Typography>
//                 </MenuItem>
//               ))}
//               <MenuItem onClick={handleCloseNavMenu}>
//               <Button
//                 onClick={() => { 
//                   console.log(formData);
//                   handleCloseDialog();
//                 }}
//                 sx={{
//                   backgroundColor: '#d32f2f',
//                   color: '#fff',
//                   fontSize: '16px',
//                   fontFamily: '"Montserrat", "Arial", sans-serif',
//                   padding: '10px 20px',
//                   borderRadius: '8px',
//                   textTransform: 'none',
//                 }}
//               >
//                 Submit
//               </Button>

//               </MenuItem>
//             </Menu>
//           </Box>

//           <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
//             {pages.map((page) => (
//               <Button
//                 key={page.name}
//                 component={Link}
//                 to={page.path}
//                 onClick={handleCloseNavMenu}
//                 sx={{
//                   my: 2,
//                   color: '#000',
//                   display: 'block',
//                   paddingInline: '20px',
//                   '&:hover': { backgroundColor: '#d32f2fbd', color: '#fff' },
//                 }}
//               >
//                 {page.name}
//               </Button>
//             ))}
//           </Box>

//           <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
//             <Button
//               variant="contained"
//               size="large"
//               onClick={handleOpenDialog} 
//               sx={{
//                 fontSize: '16px',
//                 fontFamily: '"Montserrat", "Arial", sans-serif',
//                 padding: '10px 20px',
//                 borderRadius: '8px',
//                 background: '#d32f2f',
//                 fontWeight: '500',
//                 color: '#fff',
//                 textTransform: 'none',
//                 alignSelf: 'center',
//               }}
//             >
//               Enquire Now
//             </Button>
//           </Box>
//         </Toolbar>
//       </Container>

//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//           <DialogTitle sx={{
//               fontWeight: 'bold',
//               color: '#000',
//               letterSpacing: 0.3,
//               position: 'relative',
//               fontSize: '18px',
//               paddingBottom: '5px',
//               fontFamily: '"Poppins", "Arial", sans-serif',
//               textTransform: 'capitalize',
//               textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
//           }}>Enquiry Form</DialogTitle>

//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             name="name"
//             label="Name"
//             type="text"
//             fullWidth
//             variant="outlined"
//             value={formData.name}
//             onChange={handleChange}
//             InputLabelProps={{
//               sx: {
//                 '&.Mui-focused': {
//                   color: '#d32f2f',
//                 },
//               },
//             }}
//             sx={{
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   borderColor: 'gray',
//                 },
//                 '&:hover fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '& input::placeholder': {
//                   color: 'red',
//                 },
//               },
//             }}
//           />

//           <TextField
//             margin="dense"
//             name="email"
//             label="Email"
//             type="email"
//             fullWidth
//             variant="outlined"
//             value={formData.email}
//             onChange={handleChange}
//             InputLabelProps={{
//               sx: {
//                 '&.Mui-focused': {
//                   color: '#d32f2f',
//                 },
//               },
//             }}
//             sx={{
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   borderColor: 'gray',
//                 },
//                 '&:hover fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '& input::placeholder': {
//                   color: 'red',
//                 },
//               },
//             }}
//           />

//           <TextField
//             margin="dense"
//             name="phone"
//             label="Phone Number"
//             type="tel"
//             fullWidth
//             variant="outlined"
//             value={formData.phone}
//             onChange={handleChange}
//             InputLabelProps={{
//               sx: {
//                 '&.Mui-focused': {
//                   color: '#d32f2f',
//                 },
//               },
//             }}
//             sx={{
//               '& .MuiOutlinedInput-root': {
//                 '& fieldset': {
//                   borderColor: 'gray',
//                 },
//                 '&:hover fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '&.Mui-focused fieldset': {
//                   borderColor: '#d32f2f',
//                 },
//                 '& input::placeholder': {
//                   color: 'red',
//                 },
//               },
//             }}
//           />
//         </DialogContent>

//         <DialogActions sx={{
//             padding: '0px 20px 20px 0px',
//           }}>
//           <Button onClick={handleCloseDialog} color="primary" sx={{
//                   backgroundColor: '#000',
//                   color: '#fff',
//                   fontSize: '16px',
//                   fontFamily: '"Montserrat", "Arial", sans-serif',
//                   padding: '10px 20px',
//                   borderRadius: '8px',
//                   textTransform: 'none',
//                   '&:hover': {
//                     backgroundColor: '#444',
//                   },
//                 }}
//               >
//             Cancel
//           </Button>
//           <Button onClick={() => { 
//             console.log(formData);
//             handleCloseDialog();
//           }} color="primary" sx={{
//             backgroundColor: '#d32f2f',
//             color: '#fff',
//             fontSize: '16px',
//             fontFamily: '"Montserrat", "Arial", sans-serif',
//             padding: '10px 20px',
//             borderRadius: '8px',
//             textTransform: 'none',
//             '&:hover': {
//               backgroundColor: '#d32f2fc9',
//             },
//           }}>
//             Submit
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </AppBar>
//   );
// };

// export default Navbar;





import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../assets/images/logo_one.png";
import emailjs from 'emailjs-com'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const pages = [
  { name: 'Home', path: '/' },
  { name: 'About Us', path: '/about' },
  { name: 'Products', path: '/products' },
  { name: 'Contact Us', path: '/contact' },
  { name: "Online Store", path: "/OnlineStore" },
];

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
  
    // Validate the form fields
    if (!formData.name.trim()) {
      toast.error('Name is required');
      return;
    }
  
    if (!formData.email.trim()) {
      toast.error('Email is required');
      return;
    }
  
    if (!formData.phone.trim()) {
      toast.error('Phone number is required');
      return;
    }
  
    emailjs.send('service_xs37ipj', 'template_ph7df3a', {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
    }, 'TUhvHgQ0Bp4osZuvg' )
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        handleCloseDialog();
        toast.success('Email sent successfully!');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        toast.error('Error sending email. Please try again.');
      });
  };
  

  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              alt="Logo"
              style={{ height: '80px', borderRadius: '5px' }}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                color: '#000',
                fontWeight: 'bold',
                textDecoration: 'none',
                '&:hover': { color: '#b0b0b0' },
              }}
            >
            </Typography>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="open menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" sx={{ color: '#000' }}>
                    <Link to={page.path} style={{ textDecoration: 'none', color: '#000' }}>
                      {page.name}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={handleCloseNavMenu}>
              <Button
                onClick={() => { 
                  console.log(formData);
                  handleCloseDialog();
                }}
                sx={{
                  backgroundColor: '#d32f2f',
                  color: '#fff',
                  fontSize: {
                    xs: '14px',
                    md: '16px',
                  },
                  fontFamily: '"Montserrat", "Arial", sans-serif',
                  padding: {
                    xs: '10px 15px', 
                    md: '10px 20px', 
                  },
                  borderRadius: '8px',
                  textTransform: 'none',
                }}
              >
                Enquire Now
              </Button>

              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                to={page.path}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: '#000',
                  display: 'block',
                  paddingInline: '20px',
                  '&:hover': { backgroundColor: '#d32f2fbd', color: '#fff' },
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
            <Button
              variant="contained"
              size="large"
              onClick={handleOpenDialog} 
              sx={{
                fontSize: '16px',
                fontFamily: '"Montserrat", "Arial", sans-serif',
                padding: '10px 20px',
                borderRadius: '8px',
                background: '#d32f2f',
                fontWeight: '500',
                color: '#fff',
                textTransform: 'none',
                alignSelf: 'center',
              }}
            >
              Enquire Now
            </Button>
          </Box>
        </Toolbar>
      </Container>


      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleChange}
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: '#d32f2f',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'gray',
                },
                '&:hover fieldset': {
                  borderColor: '#d32f2f',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d32f2f',
                },
                '& input::placeholder': {
                  color: 'red',
                },
              },
            }}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            onChange={handleChange}
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: '#d32f2f',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'gray',
                },
                '&:hover fieldset': {
                  borderColor: '#d32f2f',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d32f2f',
                },
                '& input::placeholder': {
                  color: 'red',
                },
              },
            }}
          />
          <TextField
            margin="dense"
            name="phone"
            label="Phone"
            type="tel"
            fullWidth
            variant="outlined"
            onChange={handleChange}
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: '#d32f2f',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'gray',
                },
                '&:hover fieldset': {
                  borderColor: '#d32f2f',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d32f2f',
                },
                '& input::placeholder': {
                  color: 'red',
                },
              },
            }}
          />
        </DialogContent>

        <DialogActions sx={{
            padding: '0px 20px 20px 0px',
          }}>
          <Button onClick={handleCloseDialog} color="primary" sx={{
                  backgroundColor: '#333',
                  color: '#fff',
                  fontSize: '16px',
                  fontFamily: '"Montserrat", "Arial", sans-serif',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#444',
                  },
                }}
              >
            Cancel
          </Button>
          <Button onClick={sendEmail} sx={{
                  backgroundColor: '#d32f2f',
                  color: '#fff',
                  fontSize: '16px',
                  fontFamily: '"Montserrat", "Arial", sans-serif',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#d32f2f94',
                  },
                }}>Submit</Button>

        </DialogActions>
      </Dialog>

      <ToastContainer
        position="bottom-right" 
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AppBar>
  );
};

export default Navbar;
