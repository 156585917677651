import React from 'react';
import { Container } from '@mui/material';
import instudioImage from '../assets/images/compressedimages/instudio.jpg';

const OnlineStore = () => {
  return (
    <Container 
      maxWidth="sm" 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: { xs: '50vh', md: '70vh' }, 
        backgroundImage: `url(${instudioImage})`,
        backgroundPosition: 'center',
        backgroundSize: { xs: '200px', md: 'contain' }, 
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        padding: { xs: '1rem', md: '2rem' }, 
      }}
    >
    </Container>
  );
}

export default OnlineStore;





