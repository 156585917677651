


import React, { useState } from 'react';
import { Box, Typography, Button, Container, TextField, Grid, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import emailjs from 'emailjs-com'; // Import EmailJS
import Banner1 from "../assets/images/carone.jpg";
import About from './About';
import ProductsPage from './Product';
import Specification from './Specification';
import Footer from "../components/Footer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Home = () => {
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Validate form fields
    if (!formData.name || !formData.email || !formData.message) {
      setSnackbarMessage('Please fill in all fields.');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return; // Stop submission if validation fails
    }
  
    emailjs
      .send(
        'service_xs37ipj', 'template_ph7df3a',
        formData,
        'TUhvHgQ0Bp4osZuvg'
      )
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
    
        setFormData({ name: '', email: '', message: '' });
        setSnackbarMessage('Email sent successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        setSnackbarMessage('Failed to send email. Please try again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };
  
  return (
    <Box>

      <Box position="relative" bgcolor="black">
        <Box
          component="img"
          src={Banner1}
          alt="Banner image"
          width="100%"
          sx={{
            objectFit: 'cover',
            opacity: 0.5,
            height: { xs: 'auto', md: '100vh' },
            display: { xs: 'none', sm: 'none', md: 'block' }
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            background: 'linear-gradient(to bottom right, #FF0000, #FF6347)',
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 0%, 0% 60%)',
            zIndex: -1
          }}
        />
        <Box
          position={{ xs: "unset", md: "absolute" }}
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Container>
            <Grid container spacing={4} alignItems="center">
              {/* Left Side: Text Section */}
              <Grid item xs={12} md={6}>
                <Box sx={{
                  padding: { xs: '32px 0px 0px', md: '32px' },
                }}>
                  <Typography
                    variant="h4"
                    color="error"
                    fontWeight="bold"
                    textAlign="left"
                    marginBottom="25px"
                    sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '24px' } }}
                  >
                    Reliability
                  </Typography>
                  <Typography
                    variant="h2"
                    color="white"
                    fontWeight="bold"
                    textAlign="left"
                    sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' } }}
                  >
                  Quality You Can Trust, Nationwide Delivery
                  </Typography>
                  <Typography
                    variant="h6"
                    color="white"
                    paddingTop="15px"
                    textAlign="left"
                    mt={1}
                    sx={{ fontSize: { xs: '0.9rem', sm: '1rem', md: '18px' } }}
                  >
                    Top-quality used engines and auto parts delivered across Canada and the US. Get reliable parts at competitive prices, shipped fast to keep you moving.
                  </Typography>
                </Box>
              </Grid>

              
              <Grid item xs={12} md={6} sx={{
                padding: { xs: '0px 0px 52px', md: '32px' },
              }}>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    backgroundColor: '#ffffffb0',
                    padding: { xs: '16px', md: '32px' },
                    borderRadius: '10px',
                    position: 'relative',
                    zIndex: 0,
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      background: '#d32f2f',
                      width: '100%',
                      height: '20%',
                      left: 0,
                      top: 0,
                      borderRadius: '0% 0% 250% 0%',
                      zIndex: -1,
                    }
                  }}
                >
                  <Typography
                    variant="h5"
                    color="#fff"
                    textAlign="center"
                    marginBottom="20px"
                  >
                    Contact Us
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="name"
                        label="Your Name"
                        variant="outlined"
                        value={formData.name}
                        onChange={handleInputChange}
                        InputLabelProps={{ style: { color: '#242424' } }}
                        InputProps={{ style: { color: '#242424' } }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: '#242424' },
                            '&:hover fieldset': { borderColor: '#242424' },
                            '&.Mui-focused fieldset': { borderColor: '#d32f2f' },
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Your Email"
                        variant="outlined"
                        value={formData.email}
                        onChange={handleInputChange}
                        InputLabelProps={{ style: { color: '#242424' } }}
                        InputProps={{ style: { color: '#242424' } }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: '#242424' },
                            '&:hover fieldset': { borderColor: '#242424' },
                            '&.Mui-focused fieldset': { borderColor: '#d32f2f' },
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="message"
                        label="Your Message"
                        variant="outlined"
                        value={formData.message}
                        onChange={handleInputChange}
                        multiline
                        rows={4}
                        InputLabelProps={{ style: { color: '#242424' } }}
                        InputProps={{ style: { color: '#242424' } }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': { borderColor: '#242424' },
                            '&:hover fieldset': { borderColor: '#242424' },
                            '&.Mui-focused fieldset': { borderColor: '#d32f2f' },
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    sx={{ marginTop: '20px', padding: '10px 20px' }}
                  >
                    Send
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <About showSpecification={false} isStandalone={false}  showButton={true} /> 
      <ProductsPage/>

      
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Specification/>


    </Box>
  );
};

export default Home;
