import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/Home';
import ProductsPage from '../pages/Product';
import ContactPage from '../pages/Contact';
import AboutPage from '../pages/About';
import NotFound from '../pages/NotFound';
import Navbar from '../components/navbar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Specification from '../pages/Specification';
import OnlineStore  from '../pages/OnlineStore';
import TermsAndConditions from '../pages/TermsAndConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';


const Routing = () => {
  return (
 <>
      <Navbar />
      <Header/>
      
    
      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductsPage showBanner={true} />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path='/specification' element={<Specification/>}/>
        <Route path="/OnlineStore" element={<OnlineStore />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
  
      <Footer/>
      </>

  );
};

export default Routing;
